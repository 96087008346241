import axios from "axios";
import ipifyApi from "./ipify.api";
import helperApi from "./helper.api";

const OAUTH_CLIENT_ID = process.env.VUE_APP_OAUTH_CLIENT_ID;
const OAUTH_CLIENT_SECRET = process.env.VUE_APP_OAUTH_CLIENT_SECRET;

const baseUrlApi = "/v1/token";
const baseUrlApiOauth = "/oauth/token";

class OAuthApi {
  async fetchRefreshToken(refreshoken: string) {
    window.logger.debug(
      "<OAuthApi.fetchRefreshToken> refreshoken: ",
      refreshoken
    );
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const request = `refresh_token=${refreshoken}&grant_type=refresh_token`;
    const authorizationBasic = btoa(
      OAUTH_CLIENT_ID + ":" + OAUTH_CLIENT_SECRET
    );

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic " + authorizationBasic,
      "cache-control": "no-cache",
      "X-public-ip": window.publicIp,
    };
    return axios.post(baseUrlApiOauth, request, {
      headers: headers,
      responseType: "json",
    });
  }

  async fetchTokenUsingPassword(username: string, password: string) {
    window.logger.debug(
      "<OAuthApi.fetchTokenUsingPassword> username: ",
      username
    );

    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const request = `username=${username}&password=${password}&grant_type=password`;
    const authorizationBasic = btoa(
      OAUTH_CLIENT_ID + ":" + OAUTH_CLIENT_SECRET
    );
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic " + authorizationBasic,
      "cache-control": "no-cache",
      "X-public-ip": window.publicIp,
    };
    return axios.post(baseUrlApiOauth, request, {
      headers: headers,
      responseType: "json",
    });
  }

  async checkToken() {
    const url = `${baseUrlApi}`;
    window.logger.debug("<OAuthApi.checkToken>");
    return axios.post(
      url,
      { headers: helperApi.baseHeaders(), responseType: "json" },
      {}
    );
  }
}

export default new OAuthApi();
