
import Vue from "vue";
import appEvents from "@/appEvent";
import helperApi from "@/apis/helper.api";

export default Vue.extend({
  name: "App",
  components: {
    Loader: () => import("@/components/base/Loader.vue"),
  },
  data: () => ({
    loader: {
      text: undefined,
      loading: false,
      hide: false,
    },
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
    count: 0,
  }),
  created() {
    window.logger.debug("Create FitApp");
    appEvents.setApp(this);

    appEvents.getEvents().forEach((item) => {
      this.$on(item.name, item.callback);
    });
  },
  mounted() {
    this.pollingSession();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const pollingSession = function () {
      self.pollingSession();

      setTimeout(pollingSession, 30000);
    };
    setTimeout(pollingSession, 30000);
  },
  methods: {
    pollingSession() {
      const expiresAt = localStorage.getItem("expires_at");

      const millis = Number(expiresAt) - Date.now();
      if (millis > 0) {
        window.logger.debug(
          "pollingSession expiresAt:",
          Number(millis / 1000 / 60).toFixed(2),
          "min"
        );
      }

      if (expiresAt !== null) {
        if (millis < 0) {
          helperApi.resetToken();
          this.snackbar.color = "error";
          this.snackbar.text = "Sessione scaduta";
          this.snackbar.show = true;
          helperApi.resetToken();
          this.$router.push({ path: "/login" });
        }
      }
      /*const accessToken = localStorage.getItem("access_token");
      const isLogin = accessToken === null ? false : true;
      if (isLogin === true) {
        oauthApi.checkToken().catch(error => {
          window.logger.debug("pollingSession - error:", error);
          this.snackbar.color = "error";
          this.snackbar.text = "Sessione scaduta";
          this.snackbar.show = true;
          helperApi.resetToken();
          this.$router.push({ path: "/login" });
        });
      }*/
    },
  },
});
