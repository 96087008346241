import Vue from "vue";
import Vuetify from "vuetify/lib";
//import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import it from "vuetify/src/locale/it";

export default new Vuetify({
  lang: {
    locales: { it },
    current: "it",
  },
  theme: {
    themes: {
      light: {
        //primary: "#0e5391",
        //secondary: "#000000",
        primary: "#000000",
        secondary: "#0e5391",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
