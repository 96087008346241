import axios from "axios";
import ipifyApi from "@/apis/ipify.api";

class HelperApi {
  baseContent() {
    const content = {};
    return content;
  }

  formHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-public-ip": window.publicIp,
    };
  }

  multipartFormHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "cache-control": "no-cache",
        "X-public-ip": window.publicIp,
      },
      responseType: "json",
    };
    return config;
  }

  baseHeadersCache() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-public-ip": window.publicIp,
    };
  }

  baseHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-public-ip": window.publicIp,
    };
  }

  arraybufferHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    const config = {
      headers: {
        Accept: "application/json",
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "X-public-ip": window.publicIp,
      },
      responseType: "arraybuffer",
    };
    return config;
  }

  blobHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    const config = {
      headers: {
        "X-public-ip": window.publicIp,
      },
      responseType: "blob",
    };
    return config;
  }

  setToken(data: {
    refresh_token: string;
    access_token: string;
    expires_in: number;
  }) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + data.access_token;
    const expiresAt = Date.now() + data.expires_in * 1000;
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("expires_at", expiresAt.toString());
  }

  resetToken() {
    axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_at");
  }
}

export default new HelperApi();
