import axios from "axios";
import helperApi from "./helper.api";

const baseUrlApi = "/v1/city";

class CityApi {
  async fetchAll() {
    const url = `${baseUrlApi}`;
    window.logger.debug("<UserApi.fetchCities>");
    return axios.get(url, {
      headers: helperApi.baseHeadersCache(),
      responseType: "json",
    });
  }
}

export default new CityApi();
