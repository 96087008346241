import axios from "axios";
import helperApi from "./helper.api";

const baseUrlApi = "/v1/associates";

class AssociatesApi {
  async nextPartnershipId() {
    const url = `${baseUrlApi}/next-partnership-id`;
    window.logger.debug("<AssociatesApi.nextPartnershipId>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async fetchById(id: number) {
    const url = `${baseUrlApi}/${id}`;
    window.logger.debug("<AssociatesApi.fetchById>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async getAccessToken(type: string, associateId: number, serviceId: number) {
    const url = `${baseUrlApi}/access/${type}/${associateId}/${serviceId}`;
    window.logger.debug("<AssociatesApi.getInAccessToken>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async accessCheckIn(token: string) {
    const url = `${baseUrlApi}/access/check-in/${token}`;
    window.logger.debug("<AssociatesApi.accessCheckIn>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async accessCheckOut(token: string) {
    const url = `${baseUrlApi}/access/check-out/${token}`;
    window.logger.debug("<AssociatesApi.accessCheckOut>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async fetchAssociates(page: number, size: number) {
    const url = `${baseUrlApi}?page=${page}&size=${size}`;
    window.logger.debug("<AssociatesApi.fetchAssociates>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async save(request: JSON) {
    const url = `${baseUrlApi}`;
    window.logger.debug("<AssociatesApi.save>");
    return axios.post(url, request, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async delete(id: number) {
    const url = `${baseUrlApi}/${id}`;
    window.logger.debug("<AssociatesApi.delete>");
    return axios.delete(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async sendPassword(id: number) {
    const url = `${baseUrlApi}/send-password/${id}`;
    window.logger.debug("<AssociatesApi.sendPassword>");
    return axios.post(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async deleteService(id: number) {
    const url = `${baseUrlApi}/service/${id}`;
    window.logger.debug("<AssociatesApi.service>");
    return axios.delete(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async genFiscalCode(request: JSON) {
    const url = `${baseUrlApi}/gen-fiscal-code`;
    window.logger.debug("<AssociatesApi.save>");
    return axios.post(url, request, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async summaryList(dateFrom: string, dateTo: string) {
    const url = `${baseUrlApi}/summary`;
    window.logger.debug("<AssociatesApi.summaryList>");
    const request = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return axios.post(url, request, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }

  async importCsenAssociate(row: string) {
    const url = `${baseUrlApi}/csen/import`;
    window.logger.debug("<AssociatesApi.importCsenAssociate>");
    const request = `row=${row}`;
    return axios.post(url, request, {
      headers: helperApi.formHeaders(),
      responseType: "json",
    });
  }
}

export default new AssociatesApi();
