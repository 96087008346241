import axios from "axios";
import helperApi from "./helper.api";

const baseUrlApi = "/v1/associations";

class AssociationsApi {
  async fetchAssociations() {
    const url = `${baseUrlApi}`;
    window.logger.debug("<UserApi.fetchAssociations>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }
}

export default new AssociationsApi();
