import cityApi from "@/apis/city.api";
import userApi from "@/apis/user.api";
import associatesApi from "@/apis/associates.api";
import associationsApi from "@/apis/associations.api";
//const isImportSoci = process.env.VUE_APP_IMPORT_SOCI;
interface AppState {
  user: any;
  cities: any;
  associations: any;
  isImportSoci: boolean;
  associate: any;
}

const state: AppState = {
  user: null,
  cities: null,
  associations: null,
  isImportSoci: process.env.VUE_APP_IMPORT_SOCI,
  associate: null,
};

const mutations = {
  setAssociate: (state: AppState, value: any) => {
    window.logger.debug("<app.store> setAssociate", value);
    state.associate = value;
  },
  setCities: (state: AppState, value: any) => {
    window.logger.debug("<app.store> setCities", value);
    state.cities = value;
  },
  setUser: (state: AppState, value: any) => {
    window.logger.debug("<app.store> setUser", value);
    state.user = value;
  },
  setAssociations: (state: AppState, value: any) => {
    window.logger.debug("<app.store> setAssociations", value);
    state.associations = value;
  },
};

const getters = {
  getUser: (state: AppState) => state.user,
  getCities: (state: AppState) => state.cities,
  getAssociations: (state: AppState) => state.associations.content,
  isImportSoci: (state: AppState) => state.isImportSoci,
  getAssociate: (state: AppState) => state.associate,
  isOperator: (state: AppState) => {
    if (state.user !== null) {
      if (
        state.user.userType === "SUPER_USER" ||
        state.user.userType === "B2B_USER"
      ) {
        return true;
      }
    }
    return false;
  },
  isSuperUser: (state: AppState) => {
    if (state.user !== null) {
      if (state.user.userType === "SUPER_USER") {
        return true;
      }
    }
    return false;
  },
};

const actions = {
  loadAssociate: async (context: any, id: number) => {
    await associatesApi.fetchById(id).then((response) => {
      window.logger.debug("fetchById response", response.data);
      context.commit("setAssociate", response.data);
    });
  },
  appInit: async (context: any) => {
    window.logger.debug("<app.store> appInit");

    context.commit("setUser", null);
    context.commit("setCities", []);
    context.commit("setAssociations", null);

    await userApi.fetchMe().then((response) => {
      window.logger.debug("fetchMe response", response.data);
      context.commit("setUser", response.data);
    });

    await associationsApi.fetchAssociations().then((response) => {
      context.commit("setAssociations", response.data);
    });

    await cityApi.fetchAll().then((response) => {
      context.commit("setCities", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
